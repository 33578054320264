import React, { ReactElement, useEffect } from 'react'

import '../i18n/i18n'

import styled from 'styled-components'
import { useQueryParam, NumberParam } from 'use-query-params'
import { isUndefined } from 'lodash'
import { withTranslation } from 'react-i18next'
import SVGSymbols from '~components/homepage/svg/icons/svg'
import CookiesPopup from '~components/homepage/sections/cookies-popup'

import {
  Container,
  ContentContainer,
  SectionWrapper,
} from '~components/homepage/styled/shared'

import LanguageContext from '~components/homepage/context/language-context'

import '../fonts/css/averta-font.css'

import GlobalStyle from '~components/homepage/sections/global-styles'
import Header from '~components/homepage/sections/header'
import FooterSection from '~components/homepage/sections/footer-section'

import articles from '~src/models/articles/shared.articles'
import Article from '~components/article/components/Article'
import PreviewArticle from '~components/articles/components/ArticlePreview'
import { mediaQueries } from '~components/homepage/styled/constants'
import { Helmet } from '~components/helmet'

const StyledContentContainer = styled(ContentContainer)`
  padding-top: 145px;
`

const RestArticles = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;

  @media (max-width: ${mediaQueries.medium}) {
    & {
      grid-template-columns: 1fr;
      justify-items: center;

      margin-bottom: 50px;
    }
  }
`

const ArticlesPage = ({ t, i18n }): ReactElement => {
  const switchLang = (lng: string): void => i18n.changeLanguage(lng)
  const [articleId] = useQueryParam('id', NumberParam)

  const redirect = (): void => {
    window.location.pathname = '/articles'
  }

  useEffect((): void => {
    if (isUndefined(articleId)) {
      redirect()
    }
  }, [articleId])

  const currentArticle = articles.find(({ id }): boolean => id === articleId)
  const restArticles = articles.filter(({ id }): boolean => id !== articleId)

  return (
    <SectionWrapper bgColor="#F7F5F1">
      <Helmet />
      <Container>
        <GlobalStyle />
        <LanguageContext.Provider value={{ t, lang: i18n.language }}>
          <CookiesPopup />
          <Header onSwitchLang={switchLang} />
          <SVGSymbols />
          <StyledContentContainer>
            <Article article={currentArticle} />
            <RestArticles>
              {restArticles.map(
                (a, index): React.ReactElement => (
                  <PreviewArticle article={a} index={index + 1} />
                )
              )}
            </RestArticles>
          </StyledContentContainer>
          <FooterSection />
        </LanguageContext.Provider>
      </Container>
    </SectionWrapper>
  )
}

export default withTranslation('translation')(ArticlesPage)
