import React, { ReactElement } from 'react'
import styled from 'styled-components'
import { map } from 'lodash'
import { SVGImage } from '~components/homepage/svg/SVGImage'
import { KlosySVGId } from '~components/homepage/svg/types/svg'

import { Article as ArticleType } from '~src/types/article'
import {
  Divider,
  Info,
  Paragraph,
  Picture,
  SourceLink,
  StyledDot,
  Subtitle,
  Title,
} from './styled'

const ArticleContainer = styled.div`
  max-width: 670px;
  margin: 0 auto;
`

interface Props {
  article: ArticleType
}

const revealAnimation = {
  'data-sal': 'fade',
  'data-sal-delay': 200,
  'data-sal-duration': 500,
  'data-sal-easing': 'ease',
}

const Article: React.FC<Props> = ({ article }): ReactElement => {
  const { title, mainPicture, content } = article || {}

  return (
    <ArticleContainer>
      <div>
        <Title>{title}</Title>
        <Picture asImage {...mainPicture} />
        {map(
          content,
          (contentUnit, index): ReactElement => {
            const t = contentUnit.type

            if (t === 'p')
              return (
                <Paragraph {...revealAnimation} key={`${t}-${contentUnit}`}>
                  {contentUnit.content}
                </Paragraph>
              )

            if (t === 'subtitle')
              return (
                <Subtitle {...revealAnimation} key={`${t}-${index}`}>
                  {contentUnit.content}
                </Subtitle>
              )

            if (t === 'subtitleGray')
              return (
                <Subtitle {...revealAnimation} gray key={`${t}-${index}`}>
                  {contentUnit.content}
                </Subtitle>
              )

            if (t === 'img')
              return <Picture asImage key={`${t}-${index}`} {...contentUnit} />

            if (t === 'info')
              return (
                <Info {...revealAnimation} key={`${t}-${index}`}>
                  {contentUnit.content}
                </Info>
              )

            if (t === 'sourceLink')
              return <SourceLink key={`${t}-${index}`} {...contentUnit} />

            if (t === 'divider')
              return (
                <Divider
                  key={`${t}-${index}`}
                  {...contentUnit}
                  {...revealAnimation}
                />
              )

            return <div></div>
          }
        )}
        <StyledDot>
          <SVGImage
            svgProps={[KlosySVGId.slideMarker, 'klosy-dot-splitter', 13, 13]}
          />
        </StyledDot>
      </div>
    </ArticleContainer>
  )
}

export default Article
